{
  "en": {
    "achievements": "Achievements",
    "activerecord": {
      "attributes": {
        "location": {
          "type": "Material"
        }
      },
      "errors": {
        "messages": {
          "record_invalid": "Validation failed: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Cannot delete record because dependent %{record} exist",
            "has_one": "Cannot delete record because a dependent %{record} exists"
          }
        },
        "models": {
          "location": {
            "attributes": {
              "type": {
                "blank": "You must select at least one recycling material"
              }
            }
          }
        }
      }
    },
    "add_marker_details": "Add marker details",
    "add_new_map_location": "New location",
    "all_rights_reserved": "All rights reserved.",
    "back_to_map": "Back to map",
    "biked_in": "biked in",
    "biweekly": "Every 2 weeks",
    "coffee": {
      "one": "coffee",
      "other": "coffees"
    },
    "coffee_price_per_unit": "$2.5",
    "confirm_deletion": "Are you sure you want to delete this marker?",
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%Y-%m-%d",
        "full": "%A, %B %d, %Y",
        "long": "%B %d, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about 1 hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about 1 month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about 1 year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost 1 year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes"
        },
        "less_than_x_seconds": {
          "one": "less than 1 second",
          "other": "less than %{count} seconds"
        },
        "over_x_years": {
          "one": "over 1 year",
          "other": "over %{count} years"
        },
        "x_days": {
          "one": "1 day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "1 month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "1 second",
          "other": "%{count} seconds"
        }
      },
      "prompts": {
        "day": "Day",
        "hour": "Hour",
        "minute": "Minute",
        "month": "Month",
        "second": "Seconds",
        "year": "Year"
      }
    },
    "directions": "Directions",
    "econub_talk_bubble": {
      "thank_you_for_support": "Thank you for supporting us in our endeavour to build the map!"
    },
    "emptying_frequency": "Emptying frequency",
    "emptying_next_date": "Next emptying date",
    "errors": {
      "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "must be accepted",
        "blank": "can't be blank",
        "confirmation": "doesn't match %{attribute}",
        "empty": "can't be empty",
        "equal_to": "must be equal to %{count}",
        "even": "must be even",
        "exclusion": "is reserved",
        "greater_than": "must be greater than %{count}",
        "greater_than_or_equal_to": "must be greater than or equal to %{count}",
        "in": "must be in %{count}",
        "inclusion": "is not included in the list",
        "invalid": "is invalid",
        "less_than": "must be less than %{count}",
        "less_than_or_equal_to": "must be less than or equal to %{count}",
        "model_invalid": "Validation failed: %{errors}",
        "not_a_number": "is not a number",
        "not_an_integer": "must be an integer",
        "odd": "must be odd",
        "other_than": "must be other than %{count}",
        "password_too_long": "is too long",
        "present": "must be blank",
        "required": "must exist",
        "taken": "has already been taken",
        "too_long": {
          "one": "is too long (maximum is 1 character)",
          "other": "is too long (maximum is %{count} characters)"
        },
        "too_short": {
          "one": "is too short (minimum is 1 character)",
          "other": "is too short (minimum is %{count} characters)"
        },
        "wrong_length": {
          "one": "is the wrong length (should be 1 character)",
          "other": "is the wrong length (should be %{count} characters)"
        }
      },
      "unacceptable_request": "A supported version is expected in the Accept header.\n",
      "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
    },
    "helpers": {
      "select": {
        "prompt": "Please select"
      },
      "submit": {
        "create": "Create %{model}",
        "submit": "Save %{model}",
        "update": "Update %{model}"
      }
    },
    "home": "Home",
    "home_title": "Drink, eat, recycle.",
    "initiatives": "Initiatives",
    "is_retim_container_full": "Is this glass container full?",
    "legend": "Legend",
    "materials": {
      "aluminium_cans": "Aluminium cans",
      "appliances": "E-Waste",
      "battery": "Used batteries",
      "cardboard": "Cardboard",
      "chemical_waste": "Chemical waste",
      "expired_meds": "Expired drugs",
      "glass": "Glass bottles",
      "lightbulb": "Lightbulbs",
      "paper": "Paper",
      "plastic": "Plastic PET",
      "polystyrene": "Packaging polystyrene",
      "polystyrene_construction": "Construction polystyrene",
      "solvents": "Solvents",
      "textile": "Reusable clothing",
      "textile_waste": "Textile waste",
      "used_car_oil": "Motor oil",
      "used_cooking_oil": "Used cooking oil",
      "waste": "Large waste",
      "wood": "Wood"
    },
    "media": "Media",
    "meta_tags": {
      "checkout_page": {
        "description": "Checkout page for donations to Econub.",
        "title": "Econub | Checkout"
      },
      "dashboard": {
        "description": "This is our app dashboard where you can obtain an JWT token to access our public API.",
        "title": "Econub | Dashboard"
      },
      "default": {
        "description": "We bike in Timișoara and continue throughout the entire country to build the EcoMap that brings recycling closer to us.",
        "title": "Econub | The most curated recycling map"
      },
      "support_nub_page": {
        "description": "We donate 10% of each donation to Stripe Climate. A carbon removal initiative to counteract climate change.",
        "title": "Econub | Donate"
      },
      "waste_oil_page": {
        "description": "Information about how we collect used cooking oil and which companies collects or recycles it.",
        "title": "Econub | Waste oil collection"
      }
    },
    "mission": "We bike in Timișoara and continue throughout the entire country to build the EcoMap that brings recycling closer to us.",
    "mission_headline": "Our mission",
    "monthly": "Monthly",
    "new_location": {
      "company": "Company",
      "company_placeholder": "Select company or enter a new one",
      "description": "Extra details",
      "description_placeholder": "Open business hours, prices for recycling materials, etc.",
      "errors": {
        "materials": "You must select at least one material",
        "name": "Invalid address",
        "title": {
          "one": "There was 1 error with your submission",
          "other": "There were %{count} errors with your submission"
        }
      },
      "map_hint": "You can drag the map marker to adjust the currently set location",
      "name": "Location address",
      "terms": "I understand that all map contributions must abide to our %{terms_link}.",
      "thank_you": "Thank you for contributing to our map! It usually takes 24-48 hours for us to review contributions and approve them.",
      "title": "New location",
      "type": "Material types",
      "type_hint": "Select all that apply",
      "website": "Company website"
    },
    "new_location_error": "We're sorry, but we can't add this location without a geotagged photo. Please activate geotagging and try again.",
    "newsletter_details": "We send at most one email per month with important updates.",
    "notify_retim": "Notify authorities",
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "negative_format": "-%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "round_mode": "default",
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Billion",
            "million": "Million",
            "quadrillion": "Quadrillion",
            "thousand": "Thousand",
            "trillion": "Trillion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB",
            "zb": "ZB"
          }
        }
      },
      "nth": {
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "open_for_details": "Open for details",
    "partners": "Partnerships",
    "privacy_policy": "Privacy",
    "recycling_points": "recycling points",
    "retim_notification_emptying_in_days": {
      "one": "The glass container will be emptied in %{count} day.",
      "other": "The glass container will be emptied in %{count} days.",
      "zero": "The glass container will be emptied today."
    },
    "retim_notification_emptying_soon": "The glass container will be emptied soon.",
    "retim_notification_rate_limit": "You've reached the limit for authorities notifications. Please try again later.",
    "retim_notification_sent": "Authorities have been notified. The glass container and will be emptied soon.",
    "save": "Save",
    "search_address": "Search an address",
    "select_language": "Select language",
    "share": "Share",
    "share_dialog": {
      "body": "Check out this recycling point I found via econub.com",
      "copied": "Copied ✅",
      "copy_link": "Copy URL",
      "thank_you": "Thanks for sharing!",
      "title": "Share this location"
    },
    "short_duration": {
      "days": "d",
      "hours": "h",
      "minutes": "m",
      "seconds": "s",
      "weeks": "w"
    },
    "skip_to_content": "Skip to main content",
    "stripe_climate_description": "Econub will contribute 10% of your coffee to removing CO₂ from the atmosphere.",
    "subscribe_label": "Subscribe to our newsletter",
    "support": {
      "array": {
        "last_word_connector": ", and ",
        "two_words_connector": " and ",
        "words_connector": ", "
      }
    },
    "support_cta": "Support Nub",
    "support_monthly_cta": "Send coffee monthly",
    "support_nub": "Support Econub",
    "support_once_cta": "Send coffee once",
    "terms_of_service": "Terms of Service",
    "terms_of_service_label": "Terms of Service",
    "thank_you_page": {
      "confirmation_message": "We appreciate your business! A confirmation email will be sent to",
      "contact_support": "If you have any questions, please email <a href='mailto:support@econub.com'>support@econub.com</a>."
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%a, %d %b %Y %H:%M:%S %z",
        "long": "%B %d, %Y %H:%M",
        "short": "%d %b %H:%M"
      },
      "pm": "pm"
    },
    "unsplash_attribution": "Photo by %{name} / %{unsplash_link}",
    "want_to_support_nub": "Keep Nub in shape with coffee to find more recycling points.",
    "waste_oil_page": {
      "how_description": "<li><span style='color: var(--color-teal)'>Cooling</span><br />After cooking, let the oil cool down.</li> <li><span style='color: var(--color-teal)'>Filtering</span><br />Make sure the oil is free of impurities or food residues, otherwise, it should be strained through a coffee filter or clean textile material you no longer use. Avoid mixing the oil with water as it can spoil its properties and make it non-recyclable.</li> <li><span style='color: var(--color-teal)'>Storing</span><br />Pour the oil into a tightly-sealed metal, plastic, or glass container.</li> <li><span style='color: var(--color-teal)'>Disposal</span><br />Once the container is filled, take it to one of the collection points below.</li>",
      "how_subtitle": "🛢️ How to collect the oil?",
      "table": {
        "caption": "Locations that collect used cooking oil",
        "header": {
          "address": "Address",
          "company": "Name"
        }
      },
      "title": "Used cooking oil collection",
      "where_subtitle": "♻️ Where to dispose the oil?"
    },
    "website": "Website",
    "weekly": "Weekly"
  },
  "ro": {
    "achievements": "Realizări",
    "activerecord": {
      "errors": {
        "models": {
          "location": {
            "attributes": {
              "type": {
                "blank": "Trebuie să selectați cel puțin un material reciclabil"
              }
            }
          }
        }
      }
    },
    "add_marker_details": "Adaugă detalii locație",
    "add_new_map_location": "Locație nouă",
    "all_rights_reserved": "Toate drepturile rezervate.",
    "back_to_map": "Înapoi la hartă",
    "biked_in": "pedalați în",
    "biweekly": "La 2 săptămâni",
    "coffee": {
      "one": "cafea",
      "other": "cafele"
    },
    "coffee_price_per_unit": "10 lei",
    "confirm_deletion": "Ești sigur că vrei să ștergi locația?",
    "date": {
      "abbr_day_names": [
        "Du",
        "Lu",
        "Ma",
        "Mi",
        "Jo",
        "Vi",
        "Sâ"
      ],
      "abbr_month_names": [
        null,
        "Ian",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Iun",
        "Iul",
        "Aug",
        "Sep",
        "Oct",
        "Noi",
        "Dec"
      ],
      "day_names": [
        "Duminică",
        "Luni",
        "Marți",
        "Miercuri",
        "Joi",
        "Vineri",
        "Sâmbătă"
      ],
      "formats": {
        "default": "%Y-%m-%d",
        "full": "%A, %d %B %Y",
        "long": "%B %d, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "Ianuarie",
        "Februarie",
        "Martie",
        "Aprilie",
        "Mai",
        "Iunie",
        "Iulie",
        "August",
        "Septembrie",
        "Octombrie",
        "Noiembrie",
        "Decembrie"
      ]
    },
    "directions": "Indicații de orientare",
    "econub_talk_bubble": {
      "thank_you_for_support": "Mulțumim că ne sprijini în această inițiativă ecologică!"
    },
    "emptying_frequency": "Frecvență golire",
    "emptying_next_date": "Următoarea golire",
    "home": "Acasă",
    "home_title": "Bem, mâncăm, ambalaje reciclăm.",
    "initiatives": "Inițiative",
    "is_retim_container_full": "Containerul de sticlă este plin?",
    "legend": "Legendă",
    "materials": {
      "aluminium_cans": "Doze de aluminiu",
      "appliances": "Electrocasnice mici",
      "battery": "Baterii uzate",
      "cardboard": "Carton",
      "chemical_waste": "Deșeuri chimice",
      "expired_meds": "Medicamente expirate",
      "glass": "Sticlă",
      "lightbulb": "Becuri",
      "paper": "Hârtie",
      "plastic": "PET",
      "polystyrene": "Polistiren din ambalaje",
      "polystyrene_construction": "Polistiren din construcții",
      "solvents": "Solvenți",
      "textile": "Îmbrăcăminte reutilizabilă",
      "textile_waste": "Deșeuri textile",
      "used_car_oil": "Ulei de motor uzat",
      "used_cooking_oil": "Ulei alimentar uzat",
      "waste": "Deșeuri voluminoase",
      "wood": "Lemn"
    },
    "media": "Media",
    "meta_tags": {
      "checkout_page": {
        "description": "10% din fiecare contribuție merge către Stripe Climate. Un demers pentru reducerea emisiilor de carbon din atmosferă.",
        "title": "Econub | Checkout"
      },
      "dashboard": {
        "description": "Acesta este portalul unde poți genera un JWT token pentru a accesa API-ul nostru public.",
        "title": "Econub | Dashboard"
      },
      "default": {
        "description": "Pedalăm în Timișoara și continuăm în toată țara pentru a construi cea mai comprehensivă EcoHartă ce aduce reciclarea mai aproape de noi.",
        "title": "Econub | Cea mai nepopulară hartă a reciclării"
      },
      "support_nub_page": {
        "description": "10% din fiecare contribuție merge către Stripe Climate. Un demers pentru reducerea emisiilor de carbon din atmosferă.",
        "title": "Econub | Susține inițiativa"
      },
      "waste_oil_page": {
        "description": "Informații despre cum colectăm uleiul alimentar și care companii îl colectează sau reciclează.",
        "title": "Econub | Colectare ulei uzat"
      }
    },
    "mission": "Pedalăm în Timișoara și continuăm în toată țara pentru a construi EcoHarta ce aduce reciclarea mai aproape de noi.",
    "mission_headline": "Misiunea noastră",
    "monthly": "Lunar",
    "new_location": {
      "company": "Companie",
      "company_placeholder": "Selectază o companie sau introdu una nouă",
      "description": "Detalii extra",
      "description_placeholder": "Orar de funcționare, preț pentru colectarea materialelor, etc.",
      "errors": {
        "materials": "Trebuie să selectați cel puțin un material",
        "name": "Adresă invalidă",
        "title": {
          "one": "A apărut o eroare la salvarea locației",
          "other": "Au apărut mai multe erori la salvarea locației"
        }
      },
      "map_hint": "Poți muta pinpoint-ul pe hartă pentru a ajusta locația curentă",
      "name": "Adresă locație",
      "terms": "Am înțeles că toate contribuțiile aduse hărții se supun %{terms_link} Econub.",
      "thank_you": "Mulțumim pentru ajutor și contribuție! Revizuirea și aprobarea ei durează 24-48 ore.",
      "title": "Locație nouă",
      "type": "Tip materiale",
      "type_hint": "Selectează toate care se aplică",
      "website": "Site companie"
    },
    "new_location_error": "Ne pare rău, dar nu putem să adaugăm o locație nouă de la o poza fără geotagging. Activați funcția geotagging pentru poze și încercați din nou.",
    "newsletter_details": "Trimitem cel mult un e-mail pe lună.",
    "notify_retim": "Notifică autoritățile",
    "open_for_details": "Apasă pentru detalii",
    "partners": "Parteneri",
    "privacy_policy": "Politica de confidențialitate",
    "recycling_points": "puncte de reciclare",
    "retim_notification_emptying_in_days": {
      "one": "Containerul de sticlă va fi golit în %{count} zi.",
      "other": "Containerul de sticlă va fi golit în %{count} zile.",
      "zero": "Containerul de sticlă va fi golit azi."
    },
    "retim_notification_emptying_soon": "Containerul de sticlă va fi golit în curând.",
    "retim_notification_rate_limit": "Ați atins limita pentru a notifica autoritățile. Vă rugăm să încercați din nou mai târziu.",
    "retim_notification_sent": "Autoritățile au fost notificate. Containerul de sticlă va fi golit în curând.",
    "save": "Salvează",
    "search_address": "Caută o adresă",
    "select_language": "Alege limba",
    "share": "Trimite",
    "share_dialog": {
      "body": "Uite ce punct de reciclare am găsit pe econub.com",
      "copied": "Copiat ✅",
      "copy_link": "Copiază adresa URL",
      "thank_you": "Mulțumim",
      "title": "Distribuie această locație"
    },
    "short_duration": {
      "days": "z",
      "hours": "h",
      "minutes": "m",
      "seconds": "s",
      "weeks": "săpt."
    },
    "skip_to_content": "Sari la conținut",
    "stripe_climate_description": "Econub va contribui cu 10% din fiecare cafea pentru a ajuta la eliminarea CO₂ din atmosferă.",
    "subscribe_label": "Abonează-te la newsletter",
    "support_monthly_cta": "Oferă cafea lunar",
    "support_nub": "Susține inițiativa",
    "support_once_cta": "Oferă cafea o dată",
    "terms_of_service": "Termeni și condiții",
    "terms_of_service_label": "termenilor și condițiilor",
    "thank_you_page": {
      "confirmation_message": "Un email de confirmare a fost trimis către adresa",
      "contact_support": "Pentru orice întrebări sau nelămuriri, ne poți scrie la adresa <a href='mailto:support@econub.com'>support@econub.com</a>."
    },
    "unsplash_attribution": "Fotografie de %{name} / %{unsplash_link}",
    "want_to_support_nub": "Ține-l pe Nub în formă cu o cafea pentru a găsi mai multe puncte de reciclare.",
    "waste_oil_page": {
      "how_description": "<li><span style='color: var(--color-teal)'>Răcire</span><br />După gătire, lăsați uleiul să se răcească.</li> <li><span style='color: var(--color-teal)'>Filtrare</span><br />Asigurați-vă că uleiul nu conține impurități sau resturi de mâncare, altfel trebuie strecurat printr-un filtru de cafea sau material textil curat pe care nu-l mai folosiți. Evitați amestecul uleiului cu apă deoarece îi poate strica proprietățile și nu mai poate fi reciclat.</li> <li><span style='color: var(--color-teal)'>Depozitare</span><br />Turnați uleiul într-un recipient de metal, plastic sau sticlă care se închide ermetic.</li> <li><span style='color: var(--color-teal)'>Debarasare</span><br />O dată umplut recipientul, transportați-l la unul din punctele de colectare de mai jos.</li>",
      "how_subtitle": "Cum se colectează uleiul uzat?",
      "table": {
        "caption": "Locații ce colectează ulei alimentar uzat",
        "header": {
          "address": "Address",
          "company": "Nume"
        }
      },
      "title": "Colectare ulei alimentar uzat",
      "where_subtitle": "Cine colectează uleiul uzat?"
    },
    "website": "Website",
    "weekly": "Săptămânal"
  }
}