import { i18n } from "../config/i18n"
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="map"
export default class extends Controller {
  static values = {
    bounds: Object,
    city: String,
    mapboxAccessToken: String,
    mapboxStyleId: String,
    mapboxTilesetName: String,
    locationsUrl: String,
    currentLocation: Object,
    layerName: String,
    centerCoordinates: Object,
    isAdmin: Boolean,
    aluminiumCansIconUrl: String,
    appliancesIconUrl: String,
    batteryIconUrl: String,
    cardboardIconUrl: String,
    chemicalWasteIconUrl: String,
    expiredMedsIconUrl: String,
    glassIconUrl: String,
    lightbulbIconUrl: String,
    paperIconUrl: String,
    plasticIconUrl: String,
    polystyreneIconUrl: String,
    polystyreneConstructionIconUrl: String,
    solventsIconUrl: String,
    textileIconUrl: String,
    textileWasteIconUrl: String,
    usedCarOilIconUrl: String,
    usedCookingOilIconUrl: String,
    wasteIconUrl: String,
  }

  static targets = [
    "materialFilterDropdown",
    "materialFilter",
    "sidebar",
    "sidebarGallery",
    "sidebarHeader",
    "sidebarDetails",
    "sidebarTitle",
    "mapLocationImageInput",
    "newMarkerButton",
    "newLocationLatitude",
    "newLocationLongitude",
    "shareDialog",
    "shareLocationLink",
    "shareLocationTwitterLink",
    "shareLocationFacebookLink",
    "shareLocationLinkedinLink",
    "shareLocationEmailLink",
    "shareLocationCopyButton",
    "emptyingDate",
  ]

  hoveredStateId = null;
  clickedStateId = null;
  materialIcons = {};
  currentZoomLevel = null;

  connect() {
    if (!mapboxgl.supported()) {
      alert('Your browser does not support our map renderer service!');
    } else {
      let initialLoad = true;
      const maxBounds = [
        [parseFloat(this.boundsValue.min_longitude), parseFloat(this.boundsValue.min_latitude)],
        [parseFloat(this.boundsValue.max_longitude), parseFloat(this.boundsValue.max_latitude)]
      ];
      const maxBBox = maxBounds.flatMap(element => element);
      this.materialIcons = {
        aluminiumCans: this.aluminiumCansIconUrlValue,
        appliances: this.appliancesIconUrlValue,
        battery: this.batteryIconUrlValue,
        cardboard: this.cardboardIconUrlValue,
        chemicalWaste: this.chemicalWasteIconUrlValue,
        expiredMeds: this.expiredMedsIconUrlValue,
        glass: this.glassIconUrlValue,
        lightbulb: this.lightbulbIconUrlValue,
        paper: this.paperIconUrlValue,
        plastic: this.plasticIconUrlValue,
        polystyrene: this.polystyreneIconUrlValue,
        polystyreneConstruction: this.polystyreneConstructionIconUrlValue,
        solvents: this.solventsIconUrlValue,
        textile: this.textileIconUrlValue,
        textileWaste: this.textileWasteIconUrlValue,
        usedCarOil: this.usedCarOilIconUrlValue,
        usedCookingOil: this.usedCookingOilIconUrlValue,
        waste: this.wasteIconUrlValue,
      }

      mapboxgl.accessToken = this.mapboxAccessTokenValue;
      window.map = new mapboxgl.Map({
        container: 'main-map',
        style: `mapbox://styles/sunergos/${this.mapboxStyleIdValue}`,
        center: this.centerCoordinatesValue,
        attributionControl: false,
        cooperativeGestures: isMobile && !this.isAdminValue,
        zoom: 12,
        maxZoom: 20,
        maxBounds: maxBounds,
      });
      const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
      });
      if (this.cityValue === 'Timişoara' && this.isAdminValue) {
        map.on('idle', () => {
          if (initialLoad) {
            geolocate.trigger();
            initialLoad = false;
          }
        });
      }
      map.on('load', () => {
        // Add map search control
        map.addControl(
          new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: i18n.t("search_address"),
            trackProximity: true,
            countries: "ro",
            language: i18n.locale,
            marker: false,
            clearAndBlurOnEsc: true,
            bbox: maxBBox,
          }),
          'top-left'
        );

        // Add map controls
        map.addControl(new mapboxgl.NavigationControl(), 'bottom-left');
        map.addControl(geolocate, 'bottom-left');
        map.addControl(new mapboxgl.FullscreenControl(), 'bottom-left');
        map.addSource(this.mapboxTilesetNameValue, {
          type: 'geojson',
          data: this.locationsUrlValue
        });
        map.addLayer({
          'id': this.layerNameValue,
          'type': 'circle',
          'source': this.mapboxTilesetNameValue,
          'layout': { 'visibility': 'visible' },
          'paint': {
            'circle-radius': [
              "case",
              ["boolean", ["feature-state", "hover"], false], 15,
              ["boolean", ["feature-state", "clicked"], false], 15,
              (isMobile ? 9 : 6)
            ],
            'circle-color': [
              "case",
              ["in", "lightbulb", ["get", "type"]],
              "#21aad5",
              ["in", "expired meds", ["get", "type"]],
              "#0f5242",
              ["in", "chemical waste", ["get", "type"]],
              "#AC0000",
              ["in", "polystyrene construction", ["get", "type"]],
              "#BE7DF4",
              ["in", "textile", ["get", "type"]],
              "hsl(0, 63%, 60%)",
              ["in", "textile waste", ["get", "type"]],
              "hsl(0, 63%, 60%)",
              ["in", "used cooking oil", ["get", "type"]],
              "hsl(40, 82%, 57%)",
              ["in", "battery", ["get", "type"]],
              "#73320F",
              ["in", "waste", ["get", "type"]],
              "#000000",
              ["in", "glass", ["get", "type"]],
              "#2db323",
              "hsl(230, 78%, 38%)"
            ],
            'circle-stroke-color': '#ffffff',
            'circle-stroke-width': 0.6
          },
          'minzoom': 7,
          'maxzoom': 20
        });

        const materialInfoPopup = new mapboxgl.Popup({
          offset: 18,
          closeButton: false,
          closeOnClick: false
        });

        if (this.currentLocationValue.id) {
          this.openSidebar(this.currentLocationValue);
        }

        map.on('click', this.layerNameValue, (e) => {
          e.preventDefault();
          this.openSidebar(e.features[0]);
        });

        map.on('click', (e) => {
          if (e.defaultPrevented === false) this.closeSidebar();
        })

        map.on('mouseenter', this.layerNameValue, (e) => {
          map.getCanvas().style.cursor = 'pointer';
          const location = e.features[0];

          if (location) {
            if (this.hoveredStateId !== null || this.hoveredStateId !== location.id) {
              map.setFeatureState(
                { source: this.mapboxTilesetNameValue, id: this.hoveredStateId },
                { hover: false }
              );
            }
            this.hoveredStateId = location.id;
            map.setFeatureState(
              { source: this.mapboxTilesetNameValue, id: this.hoveredStateId },
              { hover: true }
            );
          }

          if (!this.clickedStateId !== location.id && !isMobile) {
            // Copy coordinates array.

            const coordinates = location.geometry.coordinates.slice();
            const locationMaterialTypes = Array.isArray(location.properties.type) ? location.properties.type : JSON.parse(location.properties.type);
            let description = "<div class='popup-info'><div class='popup-info--materials'>";
            locationMaterialTypes.forEach((material, index) => {
              if (index < 3) {
                description += `<div class="popup-info--materials__material-item">
                    <img src="${this.materialIcons[this.camelize(material)]}" width="50" />
                    <div class="popup-info--materials__material-item__${this.dasherize(material)}">${i18n.t(`materials.${this.underscore(material)}`)}</div>
                  </div>`
              } else if (index === 3) {
                description += `<div class="popup-info--materials__material-item">
                    <div class="text-2xl font-bold text-center" style="color: var(--color-teal); height: 50px; width: 50px; padding-top: 15px;">+${locationMaterialTypes.length - 3}</div>
                    <div class="popup-info--materials__material-item__${this.dasherize(material)}">${i18n.t("open_for_details")}</div>
                  </div>`
              }
            });
            description += "</div></div>";

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            materialInfoPopup.setLngLat(coordinates).setHTML(description).addTo(map);
          }
        });

        map.on('mouseleave', this.layerNameValue, () => {
          map.getCanvas().style.cursor = '';
          materialInfoPopup.remove();

          if (this.hoveredStateId !== null) {
            map.setFeatureState(
              { source: this.mapboxTilesetNameValue, id: this.hoveredStateId },
              { hover: false }
            );
          }
          this.hoveredStateId = null;
        });

        if (this.hasMaterialFilterDropdownTarget) {
          if (isMobile) {
            this.materialFilterDropdownTarget.firstElementChild.style.display = 'flex';
          } else {
            this.materialFilterDropdownTarget.lastElementChild.style.cssText = 'opacity: 1; max-height: 530px';
          }
          this.materialFilterDropdownTarget.firstElementChild.addEventListener("click", (e) => {
            e.currentTarget.style.display = 'none';
            this.materialFilterDropdownTarget.lastElementChild.style.cssText = 'opacity: 1; max-height: 530px';
          });
        }
      });
    }
  }


  async getLocationImages(externalId) {
    try {
      const response = await fetch(`/locations/${externalId}/images`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      });
      const result = await response.json();
      if (result.has_images) {
        document.querySelector(".spinner").style.display = null;
        const imageElements = [];
        let imagesLoaded = 0;

        result.image_urls.forEach((imageUrl, index) => {
          const imageElement = document.createElement("img");
          imageElement.className = "slide";
          imageElement.dataset.carouselTarget = "slide";
          if (index > 0) imageElement.hidden = true;

          imageElement.addEventListener("load", () => {
            imagesLoaded++;

            if (imagesLoaded === result.image_urls.length) {
              imageElements.forEach((link) => {
                this.sidebarGalleryTarget.appendChild(link);
              });
              document.querySelector(".spinner").style.display = "none";
            }
          });

          imageElement.src = imageUrl;
          imageElement.alt = "Location image";
          imageElement.style.width = "100%";
          imageElement.style.objectFit = "contain";

          imageElements.push(imageElement);
        });
      } else {
        document.querySelector(".spinner").style.display = "none";
      }
    } catch (err) { console.log(err) }
  }

  async getLocationNextEmptyingDate(externalId) {
    try {
      const response = await fetch(`/locations/${externalId}/fetch_next_date`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const result = await response.json();
      
      if (this.hasEmptyingDateTarget) {
        this.emptyingDateTarget.innerHTML = result.date || "-";
      }
    } catch (err) {
      console.error("Error fetching next emptying date:", err);
    }
  }

  filterMapMarkers(event) {
    event.preventDefault();
    if (this.hasSidebarTarget) this.closeSidebar();
    setTimeout(() => {
      if (isMobile) {
        this.materialFilterDropdownTarget.firstElementChild.style.display = 'flex';
        this.materialFilterDropdownTarget.lastElementChild.style.cssText = 'opacity: 0; max-height: 0';
      }
    }, 300)

    const popup = document.querySelector('.mapboxgl-popup');
    if (popup) popup.remove();
    this.materialFilterTargets.forEach(el => { if (el !== event.target) el.checked = false });
    const circleColors = {
      "lightbulb": "#3991CF",
      "expired meds": "#336B33",
      "textile": "#C34A34",
      "used cooking oil": "#FECF48",
      "battery": "#5D4822",
      "waste": "#3B3B3B",
      "glass": "#4EBA52",
      "polystyrene": "#DE9082",
      "plastic": "#1A6493",
      "paper": "#5656A5",
      "cardboard": "#A98635",
    }

    if (event.target.checked) {
      this.currentZoomLevel = map.getZoom();
      map.setFilter(this.layerNameValue, ["in", event.target.value, ["get", "type"]]);
      map.setPaintProperty(this.layerNameValue, "circle-color", circleColors[event.target.value]);
      const features = map.queryRenderedFeatures({ layers: [this.layerNameValue], filter: map.getFilter(this.layerNameValue) });
      if (features.length) {
        const coordinates = features.map((feature) => feature.geometry.coordinates);
        const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);
        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinates) {
          bounds.extend(coord);
        }
        map.fitBounds(bounds, { padding: 200, maxZoom: 18 });
      }
    } else {
      map.setPaintProperty(this.layerNameValue, "circle-color", [
        "case",
        ["in", "lightbulb", ["get", "type"]],
        "#3991CF",
        ["in", "expired meds", ["get", "type"]],
        "#336B33",
        ["in", "textile", ["get", "type"]],
        "#C34A34",
        ["in", "used cooking oil", ["get", "type"]],
        "#A98635",
        ["in", "battery", ["get", "type"]],
        "#5D4822",
        ["in", "waste", ["get", "type"]],
        "#3B3B3B",
        ["in", "glass", ["get", "type"]],
        "#4EBA52",
        ["in", "cardboard", ["get", "type"]],
        "#A98635",
        "#434B59"
      ]);
      map.flyTo({ center: this.centerCoordinatesValue, zoom: this.currentZoomLevel });
      map.setFilter(this.layerNameValue, null);
      this.currentZoomLevel = null;
    }
  }

  async openSidebar(location) {
    this.sidebarGalleryTarget.querySelectorAll(".slide").forEach(e => e.remove());

    if (location?.id) {
      if (this.clickedStateId) {
        map.setFeatureState(
          { source: this.mapboxTilesetNameValue, id: this.clickedStateId },
          { clicked: false }
        );
      }

      this.clickedStateId = location.id;
      map.setFeatureState(
        { source: this.mapboxTilesetNameValue, id: this.clickedStateId },
        { clicked: true }
      );

      const coordinates = location.geometry.coordinates.slice();
      
      this.sidebarTitleTarget.innerText = location.properties.name;
      
      // Use fetch to make a Turbo Stream request
      fetch(`/locations/${location.id}/details`, {
        headers: {
          "Accept": "text/vnd.turbo-stream.html"
        }
      })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
      .then(() => {
        // Call getLocationNextEmptyingDate after the Turbo Stream has been rendered
        this.getLocationNextEmptyingDate(location.id);
      })
      .catch(error => console.error("Error fetching location details:", error));

      if (!isMobile) map.flyTo({center: coordinates, offset: [100, 0]})

      this.getLocationImages(location.id);

      this.shareLocationLinkTarget.innerHTML = location.properties.short_url;
      this.buildSocialUrls(location.properties.short_url);
    }

    this.sidebarTarget.hidden = false;
  }

  closeSidebar() {
    if (!this.sidebarTarget.hidden && this.shareDialogTarget.hidden) {
      this.sidebarTarget.hidden = true;
      if (this.sidebarTitleTarget !== this.sidebarHeaderTarget.querySelector("h2")) {
        this.sidebarTitleTarget.innerText = this.sidebarHeaderTarget.querySelector("h2").innerText;
      }
      this.sidebarDetailsTarget.innerHTML = "";
      this.resetSocialUrls();
      map.setFeatureState(
        { source: this.mapboxTilesetNameValue, id: this.clickedStateId },
        { clicked: false }
      );
      this.clickedStateId = null;
    }
  }

  openShareLocationDialog(e) {
    e.preventDefault();

    if (navigator.share) {
      navigator.share({
        title: "Econub",
        text: i18n.t("share_dialog.body"),
        url: this.shareLocationLinkTarget.innerHTML,
      }).then(() => {
        console.log(i18n.t("share_dialog.thank_you"));
      })
      .catch(console.error);
    } else {
      this.shareDialogTarget.hidden = false;
    }
  }

  copyShareLocationLink(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.shareLocationLinkTarget.innerHTML);
    event.target.innerHTML = i18n.t("share_dialog.copied");
  }

  closeShareLocationDialog() {
    this.shareDialogTarget.hidden = true;
    this.shareLocationCopyButtonTarget.innerHTML = i18n.t("share_dialog.copy_link");
  }

  buildSocialUrls(url) {
    const encodedURI = encodeURI(url);
    this.shareLocationTwitterLinkTarget.href = `https://twitter.com/intent/tweet?text=Found+this+recycling+point&url=${encodedURI}&via=econubmap`;
    this.shareLocationFacebookLinkTarget.href = `https://www.facebook.com/sharer.php?u=${encodedURI}`;
    this.shareLocationLinkedinLinkTarget.href = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedURI}`;
    this.shareLocationEmailLinkTarget.href = `mailto:?to=&body=${encodedURI}&subject=Found%20this%20recycling%20point%20via%20econub.com`;
  }

  resetSocialUrls() {
    this.shareLocationTwitterLinkTarget.href = "#";
    this.shareLocationFacebookLinkTarget.href = "#";
    this.shareLocationLinkedinLinkTarget.href = "#";
    this.shareLocationEmailLinkTarget.href = "#";
    this.shareLocationCopyButtonTarget.innerHTML = i18n.t("share_dialog.copy_link");
  }

  underscore(string) {
    return string.replace(/\s+/g, "_");
  }

  dasherize(string) {
    return string.replace(/\s+/g, "-");
  }

  camelize(string) {
    return string.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }
}
